@font-face {
    font-family: "MogulEurope";
    src: url("Mogul_Europe.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "MogulEurope";
    src: url("Mogul_Europe_Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "MogulEurope";
    src: url("Mogul_Europe_Bold_Italic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}


/*
@font-face {
    font-family: 'Mogul Europe';
    src: local('Mogul Europe'),
        url('Mogul\ Europe\ Bold_0.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Mogul Europe';
    src: local('Mogul Europe'),
        url('Mogul\ Europe_0.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Mogul Europe';
    src: local('Mogul Europe'),
        url('Mogul\ Europe\ Bold\ Italic_0.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}*/
/*
General Graphic Information
Yangi Toshkent Official font : 'Config Rounded'
Yangi Toshkent Official Color scheme : 1st - #5a9f94 / 2nd - #4a74b8 / 3rd - #213b4f (or multiply 1st and 2nd)

*/

body{
  margin: 0;
  font-family:'MogulEurope';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
  color:#5a9f94;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}

/* Ensuring the font family for options */
select, option, input {
  font-family: 'MogulEurope';
}

button {
  font-family: 'MogulEurope';
}

/* Scrollbar track (the part the mouse slides along) */
::-webkit-scrollbar-track {
  background-color: transparent;
  border: 1px solid rgba(255,255,255,0.5);
  border-radius: 5px;
}

/* Styles for the scrollbar thumb (the draggable element) */
::-webkit-scrollbar-thumb {
  background-color: black;
  border: 1px solid white;
  border-radius: 5px;
}

/* Scrollbar itself (overall size) */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.RoundButton {
  background-color: #ffffff;
  color: black;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  padding: 0 24px;
  height: 40px;
  font-size: 14px;
  font-family: 'MogulEurope', sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.DownloadButton {
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  padding: 0 24px;
  height: 40px;
  font-size: 14px;
  font-family: 'MogulEurope', sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.TextLink {
  color: white;
  font-size: 18px;
  cursor: pointer;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
}

/* Esri UI scrollbar color */
.esri-ui {
  scrollbar-color: #bfbfbf #2B2B2B;
}

.esri-widget{
  font-family: 'MogulEurope';
  background-color: rgba(0, 0, 0, 0.7);
}

.esri-popup--is-docked-top-right .esri-popup__main-container {
  max-height: 90%;
  max-width: 22.5%;
  right: 25%;
  transform: translateX(-25%);
  top: 50%;
  transform: translateY(-50%);
}

.esri-view-height-small .esri-expand .esri-widget--panel {
  max-height: 32.5vh;
}

.esri-view-width-greater-than-large .esri-ui-corner .esri-basemap-gallery {
  max-height: 31.5vh;
}

/* Scrollbar track (the part the mouse slides along) */
.InformationPanel::-webkit-scrollbar-track {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Styles for the scrollbar thumb (the draggable element) */
.InformationPanel::-webkit-scrollbar-thumb {
  background-color: black;
  border: 1px solid white;
  border-radius: 5px;
}

/* Scrollbar itself (overall size) */
.InformationPanel::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background-color: white;
  padding: 40px; 
  width: 60%; 
  height: 60%; 
  max-height: 80%; 
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  flex-direction: column; 
  overflow-y: auto;
}

iframe {
  flex-grow: 1; 
  border: none;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

